import React, {useState} from 'react'

const LIST_CASE_STUDIES = {
  "landing-page": "Landing page",
  "website-doanh-nghiep": "Website doanh nghiệp",
  "headless-cms": "Headless CMS"
};

const CaseStudyCard = ({caseStudyContent}) => {
    const [isHovering, toggleHover] = useState(false);
    let splitToGetArrayCaseStudy = caseStudyContent.frontmatter.type.split('/');
    const branding = splitToGetArrayCaseStudy[splitToGetArrayCaseStudy.length - 1];
    return (
      <div 
        className="biggest-article biggest-article-cat"
        onMouseOver={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        <div className="back-half"></div>
        <img 
          width="1024" 
          height="576" 
          className={isHovering ? "img-case-study" : ""}
          src={caseStudyContent.frontmatter.cover.childImageSharp.fluid.src}
        />
        <p className="cat">{LIST_CASE_STUDIES[branding]}</p>
        <h3>{caseStudyContent.frontmatter.title}</h3>
        <p className="excerpt">{caseStudyContent.frontmatter.description}</p>
      </div>
    )
}

export default CaseStudyCard;