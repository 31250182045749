import React from 'react'
import {Link} from 'gatsby'
import { useIntl } from "gatsby-plugin-intl";
import CaseStudyCard from "./CaseStudyCard";

const ServiceCaseStudyContent = ({dataContent, param}) => {
    const intl = useIntl()
    return (
      <section className="solutions-area pt-50 pb-70">
        <div className="container">
          <div className="section-case-study">
            <div>
              <Link 
                className='regular-tags'
                to={`/${intl.locale}/services/case-studies`}
              >
                <span 
                className={`label-case-studies label-default ${param.case === undefined ? "active-tags" : ""}`}
                >
                {intl.formatMessage({ id: "services.ServiceCaseStudyContent.All" })}
                </span>
              </Link>
              <Link 
                className='regular-tags'
                to={`/${intl.locale}/services/case-studies?case=website-doanh-nghiep`} 
              >
                <span 
                className={`label-case-studies label-default ${param.case === 'website-doanh-nghiep' ? "active-tags" : ""}`}
                >
                {intl.formatMessage({ id: "services.ServiceCaseStudyContent.WebsiteBusiness" })}
                </span>
              </Link>
              <Link 
                className='regular-tags'
                to={`/${intl.locale}/services/case-studies?case=landing-page`} 
              >
                <span 
                className={`label-case-studies label-default ${param.case === 'landing-page' ? "active-tags" : ""}`}
                >
                  Landing page
                </span>
              </Link>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12">
                {dataContent && dataContent.map(data =>
                  <a href={data.node.fields.slug} target="_blank" key={data.node.id}>
                    <CaseStudyCard caseStudyContent={data.node}/>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default ServiceCaseStudyContent