import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceCaseStudyContent from "../components/ServiceCaseStudies/ServiceCaseStudyContent";
import { graphql } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"
import * as queryString from "query-string";

const ServiceCaseStudies = ({data, location}) => {
  const param = queryString.parse(location.search);
  const serviceCaseData = data.allMarkdownRemark;
  const filterDataCaseByParam = param.case === undefined ? serviceCaseData.edges : serviceCaseData.edges.filter(service => {
    return service.node.frontmatter.type.includes(param.case);
  });
  const intl = useIntl()
  let homePageUrl = '/en/'
  if (intl.locale == 'vi') {
    homePageUrl = '/vi/'
  }

  return (
    <Layout>
      <NavbarTwo bgWhite={false} />
      <PageBanner
        pageTitle={intl.formatMessage({ id: "caseStudies" })}
        homePageText={intl.formatMessage({ id: "home" })} 
        homePageUrl="/" 
        activePageText={intl.formatMessage({ id: "services" })}
        other={{
          previousPageUrl: "/services",
          contentActivePage: intl.formatMessage({ id: "caseStudies" })
        }}
      />
      <ServiceCaseStudyContent dataContent={filterDataCaseByParam} param={param}/>
      <Footer />
    </Layout>
  )
}

export default ServiceCaseStudies;

export const pageQuery = graphql`
  query QueryCaseStudyContent {
    allMarkdownRemark(filter: {frontmatter: {type: {glob: "case-studies/*"}, language: {eq: "vi"}}}) {
      edges {
        node {
          frontmatter {
            type
            language
            title
            description
            slug
            date
            cover {
              publicURL
              childImageSharp {
                fluid(jpegQuality: 100) {
                  src
                }
              }
            }
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`;